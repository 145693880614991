function dateHelper(days) {
  let date = new Date();
  date.setDate(date.getDate() + days);
  const options = { day: "numeric", month: "short", year: "numeric" };
  return date.toLocaleDateString("en-US", options);
}
export const countries = [
  // {
  //   id: 1,
  //   name: "Angola",
  //   pic: "/NewImage/angola_11zon.jpg",
  //   price: "7000",
  //   date: dateHelper(7),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 7,
  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },
  //     {
  //       heading: "Air-Ticket",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Yellow fever Vaccination",
  //       headContent: "",
  //       content: "",
  //     },
  //   ],
  //   flight: 110000,
  //   PriceBreakage: {
  //     AFees: "6000",
  //     SCharge: "1000",
  //   },
  //   hotel: 10000,
  //   general: 5000,
  // },
  {
    id: 2,
    name: "Antigua and Barbuda",
    pic: "/NewImage/antigua-burbuda_11zon.jpg",
    price: "10800",
    date: dateHelper(8),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 8,
    Vtype: "E-Visa",
    sDuration: "30 Days",
    vValidity: "30 Days",
    pTime: "9 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 150000,
    PriceBreakage: {
      AFees: "8500",
      SCharge: "2300",
    },
    hotel: 5000,
    general: 5000,
  },
  // {
  //   id: 3,
  //   name: "Argentina",
  //   pic: "/NewImage/argentina_11zon.jpg",
  //   price: "19000",
  //   date: dateHelper(31),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 31,
  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },
  //     {
  //       heading: "Air-Ticket",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Yellow fever Vaccination",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "ITR (2 year)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Bank Statement(3 Month)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Hotel Booking",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Itinerary",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Visa Form",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "PanCard",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Adhaar Card",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: " Cover Letter",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Salary slip/Leave Certificate(If Employed)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Proof of Business",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Retirement Letter(if Retired)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: " Student id card (If Student)",
  //       headContent: "",
  //       content: "",
  //     },
  //   ],
  //   flight: 150000,
  //   PriceBreakage: {
  //     AFees: "13000",
  //     SCharge: "6000",
  //   },
  //   hotel: 7000,
  //   general: 5000,
  // },
  {
    id: 4,
    name: "Armenia",
    pic: "/NewImage/armenia_11zon.jpg",
    price: "1600",
    date: dateHelper(7),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 7,
    Vtype: "E-Visa",
    sDuration: "21 Days",
    vValidity: "60 Days",
    pTime: "7 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>be valid for at least 3 months after the intended date of departure.</li>
            <li>have at least 2 pages free from any visas/marks/stamps.</li>
            <li>be issued for no more than 10 years.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Physical Bank Statement  (6 Month)",
        headContent: "",
        content: "",
      },
      {
        heading: "Travel insurance",
        headContent: "",
        content: "",
      },
    ],
    flight: 100000,
    PriceBreakage: {
      AFees: "700",
      SCharge: "900",
    },
    hotel: 5000,
    general: 5000,
  },
  {
    id: 5,
    name: "Australia",
    pic: "/NewImage/australia_11zon.jpg",
    price: "13000",
    date: dateHelper(20),
    tag: "Get Visa  response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 20,
    Vtype: "E-Visa",
    sDuration: "90 Days",
    vValidity: "3 year",
    pTime: "20 Days",

    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the Visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },

      {
        heading: "Birth Certificate",
        headContent: "",
        content: `
          <ul>
            <li>Please submit a copy of the birth certificate.</li>
          </ul>
          `,
      },
      {
        heading: "Aadhar Card",
        headContent: "",
        content: `
          <ul>
            <li>Color copy of your Aadhar card.</li>
          </ul>
          `,
      },
      {
        heading: "ITR (2 year)",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Cover Letter",
        headContent: "",
        content: `
          <ul>
            <li>Clearly state the purpose of your travel.</li>
            <li>Clearly mention the travel dates, i.e., the beginning and end dates of the trip.</li>
            <li>Include your Passport details.</li>
          </ul>
          `,
      },
      {
        heading: "Physical Bank Statement(3 Month)",
        headContent: "",
        content: `
          <ul>
            <li>It is advisable to maintain a minimum balance of INR 3-3.5 lacs in your bank account.</li>
            <li>This balance should reflect the last six months of your account activity</li>
            <li>If your trip is being sponsored by someone else, please attach sponsor's bank statement</li>
          </ul>
          `,
      },
      {
        heading: "Business Proof (If Self-Employed)",
        headContent: "",
        content: "",
      },
      {
        heading: "Employment Documents (If Salaried)",
        headContent: "",
        content: `
          <ul>
            <li>No Objection Certificate or Leave letter from the employer</li>
            <li>Pay slips for the last three months</li>
          </ul>
          `,
      },
      {
        heading: "Student Id-Card (If Student)",
        headContent: "",
        content: `
          <ul>
            <li>A formal No Objection Certificate (NOC) from the school is required.</li>
            <li>A copy of the student ID card is required to verify proof of education.</li>
          </ul>
          `,
      },
      {
        heading: "Retired",
        headContent: "",
        content: `
          <ul>
            <li>Provide your pension statements as proof of retirement.</li>
          </ul>
          `,
      },
    ],
    flight: 100000,
    PriceBreakage: {
      AFees: "11000",
      SCharge: "2000",
    },
    hotel: 10000,
    general: 7500,
  },
  {
    id: 6,
    name: "Austria",
    pic: "/NewImage/austria_11zon.jpg",
    price: "3500",
    date: dateHelper(27),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 27,
    Vtype: "Sticker,Appointment",
    sDuration: "90 Days",
    vValidity: "Variable",
    pTime: "27 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Hotel Tickets",
        headContent: "",
        content: `
          <ul>
            <li>Provide confirmed hotel reservations covering your travel dates.</li>
            <li>Certificate of the travel agency confirming the booking and itinerary of an organised trip or any other appropriate document indicating the envisaged travel plans. </li>
            <li>Include reservations for other Schengen countries if you're visiting them.</li>
          </ul>
          `,
      },
      {
        heading: "Flight Tickets",
        headContent: "",
        content: `
          <ul>
            <li>Proof of round-trip flights or intra-Schengen travel.</li>
          </ul>
          `,
      },
      {
        heading: "Insurance",
        headContent: "",
        content: `
          <ul>
            <li>Travel medical insurance with a coverage of at least 30,000 Euros</li>
          </ul>
          `,
      },
      {
        heading: "Bank Statement",
        headContent: "",
        content: `
          <ul>
            <li>Original bank statements of the last three (3) months stamped and signed by the bank. We recommend a minimum balance of INR 3 lacs per person</li>
            <li>Ensure bank statement not more than 5 days old on the date of your appointment</li>
          </ul>
          `,
      },
      {
        heading: "Student ID proof",
        headContent: "",
        content: `
          <ul>
            <li>Proof of enrollment/ registration in school/ College/ University</li>
            <li>In case of a minor travelling alone or with only one parent, original notarized NOC by the other parent/ both parents plus copies of parent's passports or ID</li>
          </ul>
          `,
      },
      {
        heading: "If Employed",
        headContent: "",
        content: `
          <ul>
            <li>Company NOC for holiday approval, should be signed by the HR.</li>
            <li>Salary certificate of the last three (3) months of present occupation.</li>
            <li>Any document or ID proof</li>
          </ul>
          `,
      },
      {
        heading: "If funded",
        headContent: "",
        content: `
          <ul>
            <li>Last 3 years ITR</li>
            <li>If sponsored by company then cover letter must be on company letter head</li>
            <li>Sponsor Bank Statement.</li>
          </ul>
          `,
      },
      {
        heading: "Retired",
        headContent: "",
        content: `
          <ul>
            <li>Pension statements for last 3 months and/or proof of regular income generated by ownership of property or business</li>
          </ul>
          `,
      },
    ],
    flight: 100000,
    PriceBreakage: {
      para: "Fees include appointment cost only",
    },
    hotel: 7000,
    general: 7500,
  },
  {
    id: 7,
    name: "Azerbaijan",
    pic: "/NewImage/azerbaijan_11zon.jpg",
    price: "2700",
    date: dateHelper(3),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 3,
    Vtype: "E-Visa",
    sDuration: "30 Days",
    vValidity: "90 Days",
    pTime: "3 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 75000,
    PriceBreakage: {
      AFees: "2300",
      SCharge: "400",
    },
    hotel: 4000,
    general: 5000,
  },
  {
    id: 8,
    name: "Bahrain",
    pic: "/NewImage/bahrain_11zon.jpg",
    price: "3900",
    date: dateHelper(5),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 5,
    Vtype: "E-Visa",
    sDuration: "2 weeks ",
    vValidity: "2 week",
    pTime: "5 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the Visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: `
          <ul>
            <li>Confirm flight inbound and outbound tickets.</li>
          </ul>
          `,
      },
      {
        heading: "Bank Statement(3 Month)",
        headContent: "",
        content: `
          <ul>
            <li>Bank Statement under the visitor's name covering the last months with minimum closing balance of INR 1,00,000/-</li>
          </ul>
          `,
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: `
          <ul>
            <li>Hotel booking or proof of accommodation in the Kingdom of Bahrain.</li>
          </ul>
          `,
      },

      {
        heading: " Cover Letter",
        headContent: "",
        content: "",
      },
    ],
    flight: 40000,
    PriceBreakage: {
      AFees: "3000",
      SCharge: "900",
    },
    hotel: 7500,
    general: 5000,
  },
  // {
  //   id: 9,
  //   name: "Bangladesh",
  //   pic: "/NewImage/bangladesh_11zon.jpg",
  //   price: "3900",
  //   date: dateHelper(15),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 15,
  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },
  //     {
  //       heading: "Air-Ticket",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Yellow fever Vaccination",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "ITR (2 year)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Bank Statement(3 Month)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Hotel Booking",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Itinerary",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Visa Form",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "PanCard",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Adhaar Card",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: " Cover Letter",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Salary slip/Leave Certificate(If Employed)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Proof of Business",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Retirement Letter(if Retired)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: " Student id card (If Student)",
  //       headContent: "",
  //       content: "",
  //     },
  //   ],
  //   flight: 20000,
  //   PriceBreakage: {
  //     AFees: "3500",
  //     SCharge: "400",
  //   },
  //   hotel: 3000,
  //   general: 2500,
  // },
  // {
  //   id: Math.random(),
  //   name: "Barbados",
  //   pic: "/images/barbados.jpg",
  //   price: "     10000",
  //   date: new Date()
  // },
  // {
  //   id: Math.random(),
  //   name: "Belarus",
  //   pic: "/images/belarus.jpg",
  //   price: "     10000",
  //   date: new Date()
  // },
  {
    id: 10,
    name: "Belgium",
    pic: "/NewImage/belgium_11zon.jpg",
    price: "3500",
    date: dateHelper(31),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 31,
    Vtype: "Sticker,Appointment",
    sDuration: "90 Days",
    vValidity: "Variable",
    pTime: "31 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 80000,
    PriceBreakage: {
      para: "Fees include appointmen cost only",
    },
    hotel: 10000,
    general: 7500,
  },
  {
    id: 12,
    name: "Benin",
    pic: "/NewImage/benin_11zon.jpg",
    price: "6800",
    date: dateHelper(9),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 9,
    Vtype: "E-Visa",
    sDuration: "30 Days",
    vValidity: "60 Days",
    pTime: "9 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Yellow fever Vaccination",
        headContent: "",
        content: "",
      },

      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
    ],
    flight: 70000,
    PriceBreakage: {
      AFees: "4500",
      SCharge: "2300",
    },
    hotel: 5000,
    general: 5000,
  },
  // {
  //   id: 13,
  //   name: "Bhutan",
  //   pic: "/NewImage/bhutan_11zon.jpg",
  //   price: "11200",
  //   date: dateHelper(8),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 8,
  //   docs: ["Photo", "Passport", "Hotel Bookings"],
  //   flight: 0,
  //   hotel: 0,
  //   general: 1200,
  // },
  // {
  //   id: 14,
  //   name: "Brazil",
  //   pic: "/NewImage/brazil_11zon.jpg",
  //   price: "10800",
  //   date: dateHelper(17),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 17,
  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },
  //     {
  //       heading: "Air-Ticket",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Bank Statement(3 Month)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Hotel Booking",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Itinerary",
  //       headContent: "",
  //       content: "",
  //     },

  //     {
  //       heading: "Adhaar Card",
  //       headContent: "",
  //       content: "",
  //     },
  //   ],
  //   flight: 100000,
  //   PriceBreakage: {
  //     AFees: "8500",
  //     SCharge: "2300",
  //   },
  //   hotel: 7500,
  //   general: 5000,
  // },
  {
    id: 15,
    name: "Burkina Faso",
    pic: "/NewImage/burkina-faso_11zon.jpg",
    price: "8400",
    date: dateHelper(8),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 8,
    Vtype: "E-Visa",
    sDuration: "30 Days",
    vValidity: "30 Days",
    pTime: "8 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Yellow fever Vaccination",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
    ],
    flight: 100000,
    PriceBreakage: {
      AFees: "6100",
      SCharge: "2300",
    },
    hotel: 7500,
    general: 2500,
  },
  {
    id: 16,
    name: "Cambodia",
    pic: "/NewImage/cambodia_11zon.jpg",
    price: "3900",
    date: dateHelper(6),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 6,
    Vtype: "E-Visa",
    sDuration: "30 Days",
    vValidity: "90 Days",
    pTime: "6 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 30000,
    PriceBreakage: {
      AFees: "3100",
      SCharge: "800",
    },
    hotel: 3000,
    general: 2500,
  },
  {
    id: 17,
    name: "Canada",
    pic: "/NewImage/canada_11zon.jpg",
    price: "13200",
    date: dateHelper(86),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 86,
    Vtype: "Sticker",
    sDuration: "180 Days",
    vValidity: "10 year",
    pTime: "86 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Aadhaar Card",
        headContent: "",
        content: `
          <ul>
            <li>Submit a colour copy of the Aadhar card.</li>
          </ul>
          `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: `
          <ul>
            <li>Provide copies of both onward and return flight tickets.</li>
          </ul>
          `,
      },

      {
        heading: "ITR (3 year)",
        headContent: "",
        content: `
          <ul>
            <li>Include income tax returns or Form 16 for the last 3 years.</li>
          </ul>
          `,
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Cover Letter",
        headContent: "",
        content: "",
      },
      {
        heading: "Bank Statement(6 Month)",
        headContent: "",
        content: `
          <ul>
            <li>Bank statement reflecting sufficient funds to cover the trip cost for the last 6 months.</li>
          </ul>
          `,
      },
      {
        heading: "Business Proof (If Self-Employed)",
        headContent: "",
        content: "",
      },
      {
        heading: "Student Id-Card (If Student)",
        headContent: "",
        content: `
          <ul>
            <li>Include a letter of bonafide certificate from the educational institution.</li>
          </ul>
          `,
      },
      {
        heading: "Employment Documents (If Salaried)",
        headContent: "",
        content: `
          <ul>
            <li>Include pay slips for the last three months.</li>
            <li>A signed original letter on company letterhead granting leave, including relevant employment details: your name and position;</li>
            <li>your current salary;</li>
            <li>your date of hire;</li>
            <li>your employer’s name, address and telephone number.</li>
          </ul>
          `,
      },
    ],

    flight: 100000,
    PriceBreakage: {
      AFees: "11500",
      SCharge: "1700",
    },
    hotel: 10000,
    general: 7500,
  },
  {
    id: 18,
    name: "Chile",
    pic: "/NewImage/chile_11zon.jpg",
    price: "1",
    date: dateHelper(29),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 29,
    Vtype: "E-Visa",
    sDuration: "30 Days",
    vValidity: "90 Days",
    pTime: "29 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Yellow fever Vaccination",
        headContent: "",
        content: "",
      },
      {
        heading: "ITR (2 year)",
        headContent: "",
        content: "",
      },
      {
        heading: "Bank Statement(3 Month)",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Itinerary",
        headContent: "",
        content: "",
      },
      {
        heading: "Visa Form",
        headContent: "",
        content: "",
      },
      {
        heading: "PanCard",
        headContent: "",
        content: "",
      },
      {
        heading: "Adhaar Card",
        headContent: "",
        content: "",
      },
      {
        heading: " Cover Letter",
        headContent: "",
        content: "",
      },

      {
        heading: "Proof of Business",
        headContent: "",
        content: "",
      },
      {
        heading: "Retirement Letter(if Retired)",
        headContent: "",
        content: "",
      },
      {
        heading: "Salary slip/Leave Certificate(If Employed)",
        headContent: "",
        content: "",
      },
      {
        heading: " Student id card (If Student)",
        headContent: "",
        content: "",
      },
    ],
    flight: 150000,
    PriceBreakage: {
      AFees: "12500",
      SCharge: "2800",
    },
    hotel: 7500,
    general: 5000,
  },
  {
    id: 19,
    name: "China",
    pic: "/NewImage/china_11zon.jpg",
    price: "7500",
    date: dateHelper(9),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 9,
    Vtype: "Sticker",
    sDuration: "15 to 30 Days",
    vValidity: "15 to 90 Days",
    pTime: "9 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },

      {
        heading: "Bank Statement(3 Month)",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Itinerary",
        headContent: "",
        content: "",
      },
      {
        heading: "Bank Balance Certificate",
        headContent: "",
        content: "",
      },
      {
        heading: "Cover Letter",
        headContent: "",
        content: "",
      },
    ],
    flight: 30000,
    PriceBreakage: {
      AFees: "4000",
      SCharge: "3500",
    },
    hotel: 3000,
    general: 2500,
  },
  // {
  //   id: 20,
  //   name: "Colombia",
  //   pic: "/NewImage/colombia_11zon.jpg",
  //   price: "14800",
  //   date: dateHelper(29),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 29,
  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },
  //     {
  //       heading: "Air-Ticket",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "ITR (2 year)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Bank Statement(3 Month)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Hotel Booking",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Itinerary",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Business Certificate",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Adhaar Card",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: " Cover Letter",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Travel insurance",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Salary slip/Leave Certificate(If Employed)",
  //       headContent: "",
  //       content: "",
  //     },
  //   ],
  //   flight: 180000,
  //   PriceBreakage: {
  //     AFees: "12000",
  //     SCharge: "2800",
  //   },
  //   hotel: 5000,
  //   general: 5000,
  // },
  // {
  //   id: Math.random(),
  //   name: "Comoros",
  //   pic: "/images/comoros.jpg",
  //   price: "     10000",
  //   date:new Date()
  // },
  // {
  //   id: Math.random(),
  //   name: "Cook Island",
  //   pic: "/images/cook-island.jpg",
  //   price: "     10000",
  //   date:new Date()
  // },
  // {
  //   id: 21,
  //   name: "Costa Rica",
  //   pic: "/NewImage/costa-rica_11zon.jpg",
  //   price: "4800",
  //   date: dateHelper(24),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 24,
  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },
  //     {
  //       heading: "Air-Ticket",
  //       headContent: "",
  //       content: "",
  //     },
  //   ],
  //   flight: 55000,
  //   PriceBreakage: {
  //     AFees: "3000",
  //     SCharge: "1800",
  //   },
  //   hotel: 10000,
  //   general: 7000,
  // // },
  // {
  //   id: 22,
  //   name: "Cuba",
  //   pic: "/NewImage/cuba_11zon.jpg",
  //   price: "9500",
  //   date: dateHelper(23),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 23,
  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },
  //     {
  //       heading: "Air-Ticket",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Bank Statement(6 Month)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Hotel Booking",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Affidavit",
  //       headContent: "",
  //       content: "",
  //     },

  //     {
  //       heading: " Cover Letter",
  //       headContent: "",
  //       content: "",
  //     },
  //   ],
  //   flight: 35000,
  //   PriceBreakage: {
  //     AFees: "7200",
  //     SCharge: "2300",
  //   },
  //   hotel: 4500,
  //   general: 5000,
  // },
  {
    id: 23,
    name: "Cyprus",
    pic: "/NewImage/cyprus_11zon.jpg",
    price: "4500",
    date: dateHelper(11),
    tag: "Get Visa response on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 11,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Travel Insurance",
        headContent: "",
        content: "",
      },
      {
        heading: "ITR (2 year)",
        headContent: "",
        content: "",
      },
      {
        heading: "Bank Statement(3 Month)",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Itinerary",
        headContent: "",
        content: "",
      },

      {
        heading: " Cover Letter",
        headContent: "",
        content: "",
      },
    ],
    flight: 55000,
    PriceBreakage: {
      AFees: "2500",
      SCharge: "2000",
    },
    hotel: 5500,
    general: 5000,
  },
  {
    id: 24,
    name: "Czechia",
    pic: "/NewImage/czechia_11zon.jpg",
    price: "3500",
    date: dateHelper(15),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 15,
    Vtype: "Sticker,Appointment",
    sDuration: "90 Days",
    vValidity: "Variable",
    pTime: "15 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 90000,
    PriceBreakage: {
      para: "Fees include appointment cost only",
    },
    hotel: 5000,
    general: 5000,
  },
  {
    id: 25,
    name: "Egypt",
    pic: "/NewImage/egypt_11zon.jpg",
    price: "4200",
    date: dateHelper(6),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 6,
    Vtype: "E-Visa",
    sDuration: "30 Days",
    vValidity: "3 months",
    pTime: "6 Working Days",
    multiEntryHeading:"Tourist Visa - Multi Entry",
    mEntryPrice:"5500",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Yellow fever Vaccination",
        headContent: "",
        content: "",
      },
      {
        heading: "ITR (2 year)",
        headContent: "",
        content: "",
      },
      {
        heading: "Bank Statement(3 Month)",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Itinerary",
        headContent: "",
        content: "",
      },
      {
        heading: "Visa Form",
        headContent: "",
        content: "",
      },
      {
        heading: "PanCard",
        headContent: "",
        content: "",
      },
      {
        heading: "Adhaar Card",
        headContent: "",
        content: "",
      },
      {
        heading: " Cover Letter",
        headContent: "",
        content: "",
      },

      {
        heading: "Proof of Business",
        headContent: "",
        content: "",
      },
      {
        heading: "Salary slip/Leave Certificate(If Employed)",
        headContent: "",
        content: "",
      },
      {
        heading: "Retirement Letter(if Retired)",
        headContent: "",
        content: "",
      },
      {
        heading: " Student id card (If Student)",
        headContent: "",
        content: "",
      },
    ],
    flight: 30000,
    // PriceBreakage: {
    //   AFees: "2700",
    //   SCharge: "1500",
    // },
    hotel: 7500,
    general: 5000,
  },
  {
    id: 26,
    name: "Ethiopia",
    pic: "/NewImage/ehtiopia_11zon.jpg",
    price: "8300",
    date: dateHelper(7),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 7,
    Vtype: "E-Visa",
    sDuration: "30 Days",
    vValidity: "30 Days",
    pTime: "7 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Yellow fever Vaccination",
        headContent: "",
        content: "",
      },

      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
    ],
    flight: 30000,
    PriceBreakage: {
      AFees: "7000",
      SCharge: "1300",
    },
    hotel: 4500,
    general: 4000,
  },
  // {
  //   id: Math.random(),
  //   name: "El Salvador",
  //   pic: "/images/el-salvador.jpg",
  //   price: "     10000",
  //   date:new Date()
  // },
  // {
  //   id: 27,
  //   name: "Equitorial Guinea",
  //   pic: "/NewImage/equitorial-guinea_11zon.jpg",
  //   price: "8700",
  //   date: dateHelper(8),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 8,
  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },
  //   ],
  //   flight: 60000,
  //   PriceBreakage: {
  //     AFees: "6300",
  //     SCharge: "2400",
  //   },
  //   hotel: 5000,
  //   general: 4000,
  // },
  {
    id: 28,
    name: "Estonia",
    pic: "/NewImage/estonia_11zon.jpg",
    price: "3500",
    date: dateHelper(31),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 31,
    Vtype: "Sticker,Appointment",
    sDuration: "90 Days",
    vValidity: "Variable",
    pTime: "31 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 65000,
    PriceBreakage: {
      para: "Fees include appointment cost only",
    },
    hotel: 5000,
    general: 4000,
  },
  // {
  //   id: 29,
  //   name: "Fiji",
  //   pic: "/NewImage/fiji_11zon.jpg",
  //   tag: "Get Visa response on time or 100% return",
  //   price: "     10000",
  //   date: dateHelper(27),
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 27,
  //   docs: ["Photo", "Passport"],
  //   flight: 0,
  //   hotel: 0,
  //   general: 0,
  // },
  {
    id: 30,
    name: "Finland",
    pic: "/NewImage/finland_11zon.jpg",
    price: "3500",
    date: dateHelper(31),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 31,
    Vtype: "Sticker,Appointment",
    sDuration: "90 Days",
    vValidity: "Variable",
    pTime: "31 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Hotle Booking",
        headContent: "",
        content: `
          <ul>
            <li>Submit hotel reservations for your entire stay in the Schengen area.</li>
          </ul>
        `,
      },
      {
        heading: "Cover Letter",
        headContent: "",
        content: `
          <ul>
            <li>Write a cover letter that states the purpose of your travel, travel dates, passport number, and details of any accompanying applicants</li>
          </ul>
        `,
      },
      {
        heading: "Flight Booking",
        headContent: "",
        content: `
          <ul>
            <li>Provide a confirmed roundtrip ticket and reservations for internal transfers within Schengen states (e.g., flight, train itinerary, or car rental).</li>
          </ul>
        `,
      },
      {
        heading: "Bank Statement",
        headContent: "",
        content: `
          <ul>
            <li>Provide your last three months' bank statement with a balance covering the cost of your trip. </li>
            <li>The statement should not be more than four days old on your appointment day. </li>
            <li>Recommended bank balance is at least 3 lacs per person for the entire trip </li>
          </ul>
        `,
      },
      {
        heading: "If Student",
        headContent: "",
        content: `
          <ul>
            <li>Present proof of enrollment at your school or institute. </li>
          </ul>
        `,
      },
      {
        heading: "If Employed",
        headContent: "",
        content: `
          <ul>
            <li>Leave letter on company letterhead with authorised signatory's name, designation, contact details, your name, salary, years of employment, and leave approval date. </li>
            <li>Last 3-three months' pay slips </li>
          </ul>
        `,
      },
    ],
    flight: 60000,
    PriceBreakage: {
      para: "Fees include appointment cost only",
    },
    hotel: 10000,
    general: 7500,
  },
  {
    id: 31,
    name: "France",
    pic: "/NewImage/france_11zon.jpg",
    price: "3500",
    date: dateHelper(31),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 31,
    Vtype: "Sticker,Appointment",
    sDuration: "90 Days",
    vValidity: "Variable",
    pTime: "31 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Cover Letter",
        headContent: "",
        content: `
          <ul>
            <li>Clearly state the purpose of your travel.</li>
            <li>Clearly mention the travel dates, i.e., the beginning and end dates of the trip.</li>
            <li>Mention your Passport number in the Cover Letter.</li>
          </ul>
        `,
      },
      {
        heading: "Bank Statement",
        headContent: "",
        content: `
          <ul>
            <li>Submit your own recent bank statement, covering the past three months.</li>
            <li>The statement should be no more than one week old at the time of application.</li>
            <li>Recommended bank balance is at least 150 euros per person/per day for the entire trip.</li>
            <li>It is advisable to maintain consistent balance in your account and not transfer money before the appointment.</li>
          </ul>
        `,
      },
      {
        heading: "ITR (3 Year)",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: `
          <ul>
            <li>Display proof of hotel bookings covering the entire duration of your stay in France.</li>
          </ul>
        `,
      },
      {
        heading: "Flight Booking",
        headContent: "",
        content: `
          <ul>
            <li>Confirmed roundtrip ticket. Also include, Reservations for internal transfer within Schengen states (flight, train itinerary or car rental) </li>
          </ul>
        `,
      },
      {
        heading: "If Married",
        headContent: "",
        content: `
          <ul>
            <li>Marriage certificate copy, rental/property agreement or Aadhar card</li>
            <li>If Divorced, divorce papers</li>
          </ul>
        `,
      },
      {
        heading: "Visa Form",
        headContent: "",
        content: `
          <ul>
            <li>Put your signature on the application form and carry it along on the day of appointment.</li>
          </ul>
        `,
      },
      {
        heading: "If Retired",
        headContent: "",
        content: `
          <ul>
            <li>Proof of Retirement.</li>
            <li>Proof of regular income generated by ownership of property or business.</li>
          </ul>
        `,
      },
    ],
    flight: 60000,
    PriceBreakage: {
      para: "Fees include appointment cost only",
    },
    hotel: 6000,
    general: 7500,
  },
  {
    id: 32,
    name: "Georgia",
    pic: "/NewImage/georgia_11zon.jpg",
    price: "5100",
    date: dateHelper(7),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 7,
    Vtype: "E-Visa",
    sDuration: "30 Days",
    vValidity: "120 Days",
    pTime: "7 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Bank Statement(6 Month)",
        headContent: "",
        content: `
          <ul>
            <li>Provide the last six months of your online bank statement with a recommended balance of at least 1 lac per person
Upload the original, uncompressed files exactly as received from your bank via email or net banking.</li>
          </ul>
        `,
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "If Employed",
        headContent: "",
        content: `
          <ul>
            <li>Last 3 months payslip is required</li>
          </ul>
        `,
      },
      {
        heading: "Age<18",
        headContent: "",
        content: `
          <ul>
            <li>Needed if a minor is traveling with a single or no parent</li>
            <li>If a minor is travelling with a single parent, include a No Objection Certificate (NOC).</li>
          </ul>
        `,
      },
      {
        heading: "Aadhaar Card",
        headContent: "",
        content: `
          <ul>
            <li>Aadhar card details need to be verified using OTP.</li>
          </ul>
        `,
      },
      {
        heading: "ITR",
        headContent: "",
        content: `
          <ul>
            <li>Income tax return for the previous financial year, if available</li>
            <li>Income Tax login credentials for the embassy to verify the authenticity of your returns</li>
          </ul>
        `,
      },
    ],
    flight: 40000,
    PriceBreakage: {
      AFees: "3100",
      SCharge: "2000",
    },
    hotel: 3500,
    general: 5000,
  },
  {
    id: 33,
    name: "Germany",
    pic: "/NewImage/germany_11zon.jpg",
    price: "3500",
    date: dateHelper(51),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 51,
    Vtype: "Sticker,Appointment",
    sDuration: "90 Days",
    vValidity: "Variable",
    pTime: "51 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Flight Ticket",
        headContent: "",
        content: `
          <ul>
            <li>Include a flight reservation for your trip both onward and return,</li>
          </ul>
        `,
      },
      {
        heading: "Cover Letter",
        headContent: "",
        content: `
          <ul>
            <li>Clearly state the purpose and duration of your travel.</li>
          </ul>
        `,
      },
      {
        heading: "Travel Insurance",
        headContent: "",
        content: `
          <ul>
            <li>Sign a declaration of travel with valid medical insurance.</li>
          </ul>
        `,
      },
      {
        heading: "ITR (3 Year)",
        headContent: "",
        content: `
          <ul>
            <li>Sign a declaration of travel with valid medical insurance.</li>
          </ul>
        `,
      },
      {
        heading: "Hotel Ticket",
        headContent: "",
        content: `
          <ul>
            <li>Include proof of hotel reservations, rental of a holiday home, or campus residence reservation.</li>
            <li>If staying with a family member or friend, provide proof of sponsorship, a confirmation from the third person with signature, proof of address, and a copy of their passport or German ID card</li>
            <li>If travelling to multiple Member States, show proof of accommodation in each.</li>
          </ul>
        `,
      },
      {
        heading: "If Student",
        headContent: "",
        content: `
          <ul>
            <li>A formal No Objection Certificate (NOC) from the school is required </li>
            <li>A copy of the student ID card is required to verify proof of education.</li>
          </ul>
        `,
      },
      {
        heading: "If Employed",
        headContent: "",
        content: `
          <ul>
            <li>Include your bank statements for the last 6 months.</li>
            <li>Document or ID to verify your contract as an employee.</li>
            <li>Pay slips for the last 3-three months.</li>
            <li>Employer's statement (leave sanction letter).</li>
          </ul>
        `,
      },
      {
        heading: "If Married",
        headContent: "",
        content: `
          <ul>
            <li>If married please provide a marriage certificate as a proof.</li>
          </ul>
        `,
      },
    ],
    flight: 80000,
    PriceBreakage: {
      para: "Fees include appointment cost only",
    },
    hotel: 10000,
    general: 7500,
  },
  // {
  //   id: 34,
  //   name: "Ghana",
  //   pic: "/NewImage/ghana_11zon.jpg",
  //   price: "12500",
  //   date: dateHelper(24),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 24,

  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },
  //     {
  //       heading: "Air-Ticket",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Yellow fever Vaccination",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "ITR (2 year)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Bank Statement(6 Month)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Hotel Booking",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: " Cover Letter",
  //       headContent: "",
  //       content: "",
  //     },
  //   ],
  //   flight: 80000,
  //   PriceBreakage: {
  //     AFees: "10300",
  //     SCharge: "2300",
  //   },
  //   hotel: 6500,
  //   general: 7000,
  // },
  {
    id: 35,
    name: "Greece",
    pic: "/NewImage/greece_11zon.jpg",
    price: "3500",
    date: dateHelper(4),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 4,
    Vtype: "Sticker,Appointment",
    sDuration: "90 Days",
    vValidity: "Variable",
    pTime: "4 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Bank Statement",
        headContent:"",
        content: `
          <ul>
            <li>Submit your own recent bank statement, covering the past sixmonths, with an official stamp and signature from the bank. </li>
            <li>The statement should be no more than one week old at the time of application.</li>
            <li>Recommended bank balance is at least Euro 50 (INR 4,300/-) per day per person for the entire trip.</li>
          </ul>
        `,
      },
      {
        heading: "Visa Form",
        headContent:"",
        content: `
          <ul>
            <li>A completed visa application form Signed by the traveller</li>
          </ul>
        `,
      },
      {
        heading: "Cover Letter",
        headContent:"",
        content: `
          <ul>
            <li>Clearly state the purpose and duration of your travel.</li>
          </ul>
        `,
      },
      {
        heading: "Flight Ticket",
        headContent:"",
        content: `
          <ul>
            <li>Return or round flight ticket. </li>
            <li>If the applicant is travelling to several Schengen States, proof of intra-Schengen flight reservation, train itinerary or car rental.</li>
          </ul>
        `,
      },
      {
        heading: "Travel Itinerary",
        headContent:"",
        content: `
          <ul>
            <li>Your intended travel details, either through a travel agency certificate or a detailed itinerary.</li>
          </ul>
        `,
      },
      {
        heading: "Pension statements",
        headContent:"",
        content: `
          <ul>
            <li>Pension statements for the last three months</li>
            <li>Proof of regular income generated by ownership of property or business.</li>
          </ul>
        `,
      },
      {
        heading: "Hotel Booking",
        headContent:"",
        content: `
          <ul>
            <li>Hotel reservations, rental of holiday home or campus residence reservation</li>
            <li> If the applicant intends to stay with a family member or a friend, proof of sponsorship and/or private accommodation from the host</li>
          </ul>
        `,
      },
      {
        heading: "If Employed",
        headContent:"",
        content: `
          <ul>
            <li>Employment contract from current employer</li>
            <li>Employer's letter confirming approved leave.</li>
            <li>Provide the original company letter on official letterhead, including the authorized signatory's name, designation, contact information, and company seal stamp.</li>
            <li>Pay slips of last 3 months</li>
          </ul>
        `,
      },
      {
        heading: "If Married",
        headContent:"",
        content: `
          <ul>
            <li>Attested copy of original Marriage certificate. This is not required if Spouse name is present in the passport</li>
          </ul>
        `,
      },
      {
        heading: "Retired",
        headContent:"",
        content: `
          <ul>
            <li>Provide your pension statements as proof of retirement.</li>
          </ul>
        `,
      },
    ],
    flight: 40000,
    PriceBreakage: {
      para: "Fees include appoinment cost only",
    },
    hotel: 6000,
    general: 5000,
  },
  {
    id: 36,
    name: "Guinea",
    pic: "/NewImage/guinea_11zon.jpg",
    price: "8900",
    date: dateHelper(10),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 10,
    Vtype: "E-Visa",
    sDuration: "30 Days",
    vValidity: "90 Days",
    pTime: "10 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Yellow fever Vaccination",
        headContent: "",
        content: "",
      },
      {
        heading: "Bank Statement(6 Month)",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Cover Letter",
        headContent: "",
        content: "",
      },
    ],
    flight: 80000,
    PriceBreakage: {
      AFees: "6700",
      SCharge: "2200",
    },
    hotel: 6500,
    general: 5000,
  },
  // {
  //   id: 37,
  //   name: "Guyana",
  //   pic: "/NewImage/guyana_11zon.jpg",
  //   price: "1100",
  //   date: dateHelper(30),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 30,
  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },
  //     {
  //       heading: "Air-Ticket",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Yellow fever Vaccination",
  //       headContent: "",
  //       content: "",
  //     },

  //     {
  //       heading: "Bank Statement(3 to 6 Month)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Hotel Booking",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: " Cover Letter",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: " Medical Report",
  //       headContent: "",
  //       content: "",
  //     },
  //   ],
  //   flight: 250000,
  //   PriceBreakage: {
  //     AFees: "800",
  //     SCharge: "300",
  //   },
  //   hotel: 10000,
  //   general: 7500,
  // },
  {
    id: 38,
    name: "Hong Kong",
    pic: "/NewImage/hong-kong_11zon.jpg",
    price: "500",
    date: dateHelper(1),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 1,
    Vtype: "Pre Arrival Registration",
    sDuration: "14 Days",
    vValidity: "6 months",
    pTime: "1 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
    ],
    flight: 65000,
    // PriceBreakage: {
    //   AFees: "400",
    //   SCharge: "1300",
    // },
    hotel: 5500,
    general: 5000,
  },
  {
    id: 39,
    name: "Hungary",
    pic: "/NewImage/hungary_11zon.jpg",
    price: "3500",
    date: dateHelper(32),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 32,
    Vtype: "Sticker,Appointment",
    sDuration: "90 Days",
    vValidity: "Variable",
    pTime: "32 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Yellow fever Vaccination",
        headContent: "",
        content: "",
      },
      {
        heading: "ITR (2 year)",
        headContent: "",
        content: "",
      },
      {
        heading: "Bank Statement(3 Month)",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Itinerary",
        headContent: "",
        content: "",
      },
      {
        heading: "Visa Form",
        headContent: "",
        content: "",
      },
      {
        heading: "PanCard",
        headContent: "",
        content: "",
      },
      {
        heading: "Adhaar Card",
        headContent: "",
        content: "",
      },
      {
        heading: " Cover Letter",
        headContent: "",
        content: "",
      },

      {
        heading: "Proof of Business",
        headContent: "",
        content: "",
      },
      {
        heading: "Retirement Letter(if Retired)",
        headContent: "",
        content: "",
      },
      {
        heading: "Salary slip/Leave Certificate(If Employed)",
        headContent: "",
        content: "",
      },
      {
        heading: " Student id card (If Student)",
        headContent: "",
        content: "",
      },
    ],
    flight: 65000,
    PriceBreakage: {
      para: "Fees include appointment cost only",
    },
    hotel: 5500,
    general: 5000,
  },
  {
    id: 40,
    name: "Indonesia",
    pic: "/NewImage/indonesia_11zon.jpg",
    price: "3400",
    date: dateHelper(1),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 1,
    Vtype: "E-Visa",
    sDuration: "30 Days",
    vValidity: "90 Days",
    pTime: "1 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 45000,
    PriceBreakage: {
      AFees: "3000",
      SCharge: "400",
    },
    hotel: 2500,
    general: 4000,
  },
  // {
  //   id: 41,
  //   name: "Israel",
  //   pic: "/NewImage/israel_11zon.jpg",
  //   price: "12800",
  //   date: dateHelper(32),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 32,
  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },
  //     {
  //       heading: "Air-Ticket",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Yellow fever Vaccination",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "ITR (2 year)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Bank Statement(3 Month)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Hotel Booking",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Itinerary",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Visa Form",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "PanCard",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Adhaar Card",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: " Cover Letter",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Salary slip/Leave Certificate(If Employed)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Proof of Business",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Retirement Letter(if Retired)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: " Student id card (If Student)",
  //       headContent: "",
  //       content: "",
  //     },
  //   ],
  //   flight: 55000,
  //   PriceBreakage: {
  //     AFees: "10500",
  //     SCharge: "2300",
  //   },
  //   hotel: 10000,
  //   general: 7500,
  // },
  {
    id: 42,
    name: "Italy",
    pic: "/NewImage/italy_11zon.jpg",
    price: "3500",
    date: dateHelper(33),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 33,
    Vtype: "Sticker,Appointment",
    sDuration: "90 Days",
    vValidity: "Variable",
    pTime: "33 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Hotle Booking",
        headContent: "",
        content: `
          <ul>
            <li>Provide confirmed hotel reservations covering your travel dates.</li>
            <li>Include reservations for other Schengen countries if you're visiting them.</li>
          </ul>
        `,
      },
      {
        heading: "Flight Booking",
        headContent: "",
        content: `
          <ul>
            <li>Copy of flight bookings for the whole duration of the trip and</li>
            <li>Proof of any other internal travel (bus/train/flight) reservations.</li>
          </ul>
        `,
      },
      {
        heading: "IT return",
        headContent: "",
        content: `
          <ul>
            <li>Include ITR acknowledgement for the last three assessment years or Form 16A 
Should show a barcode.</li>
          </ul>
        `,
      },
      {
        heading: "Travel Insurance",
        headContent: "",
        content: `
          <ul>
            <li>Get travel insurance with coverage of at least 30,000 Euros.</li>
            <li>Mandatory for a minimum of 10 days from your travel date.</li>
          </ul>
        `,
      },
      {
        heading: "Cover letter",
        headContent: "",
        content: `
          <ul>
            <li>Mention your name, travel details, expenses, and purpose of the visit.</li>
          </ul>
        `,
      },
      {
        heading: "Bank Statement",
        headContent: "",
        content: `
          <ul>
            <li>Submit your own recent bank statement, covering the past three(6) months, with an official stamp and signature from the bank.</li>
            <li>For Delhi jurisdiction, include 6 months' statements.</li>
            <li>The statement should be no more than one week old at the time of application.</li>
            <li>Recommended bank balance is at least 150 euros per person per day.</li>
          </ul>
        `,
      },
      {
        heading: "Bank Statement",
        headContent: "",
        content: `
          <ul>
            <li>Submit your own recent bank statement, covering the past three(6) months, with an official stamp and signature from the bank.</li>
            <li>For Delhi jurisdiction, include 6 months' statements.</li>
            <li>The statement should be no more than one week old at the time of application.</li>
            <li>Recommended bank balance is at least 150 euros per person per day.</li>
          </ul>
        `,
      },
      {
        heading: "If Employed",
        headContent: "",
        content: `
          <ul>
            <li>Provide your latest 3 months' pay slips.</li>
            <li>Letter from Indian Company or Institution, explaining purpose of the visit, professional status of applicant, and details regarding activities and business relation with Italian company.The letter must be signed by the authorized person, whose name and charge must be clearly identified. </li>
            <li>Include a No Objection Certificate (NOC) with leave dates from your employer on company letterhead.</li>
          </ul>
        `,
      },
      {
        heading: "If Married",
        headContent: "",
        content: `
          <ul>
            <li>Marriage certificate is required.</li>           
          </ul>
        `,
      },
    ],
    flight: 65000,
    PriceBreakage: {
      para: "Fees include appointment cost only",
    },
    hotel: 8500,
    general: 7000,
  },
  {
    id: 43,
    name: "Japan",
    pic: "/NewImage/japan_11zon (1).jpg",
    price: "3500",
    date: dateHelper(8),
    tag: "Get Visa response on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 8,
    Vtype: "E-Visa",
    sDuration: "15 Days",
    vValidity: "90 Days",
    pTime: "8 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Yellow fever Vaccination",
        headContent: "",
        content: "",
      },
      {
        heading: "ITR (2 year)",
        headContent: "",
        content: "",
      },
      {
        heading: "Residence proof",
        headContent: "",
        content: `
          <ul>
            <li>Any residence proof in jurisdiction of the consulate</li>
          </ul>
          `,
      },
      {
        heading: "Bank Statement(6 Month)",
        headContent: "",
        content: `
          <ul>
            <li>Provide your personal bank statements from the last six months, or the last three months if applicable.</li>
            <li>Recommended minimum bank balance of INR 1,50,000/- per traveller</li>
          </ul>
          `,
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: `
          <ul>
            <li>Hotel booking confirmation for the entire stay in Japan</li>
          </ul>
          `,
      },
      {
        heading: "Itinerary",
        headContent: "",
        content: "",
      },
      {
        heading: "Visa Form",
        headContent: "",
        content: `
          <ul>
            <li>Hotel booking confirmation for the entire stay in Japan</li>
          </ul>
          `,
      },
      {
        heading: "PanCard",
        headContent: "",
        content: "",
      },
      {
        heading: "Adhaar Card",
        headContent: "",
        content: "",
      },
      {
        heading: " Cover Letter",
        headContent: "",
        content: `
          <ul>
            <li>Clearly state the purpose of your trip.</li>
            <li>Specify the number of days you intend to stay.</li>
            <li>If self employed, the cover letter should be presented on your business letterhead.</li>
          </ul>
          `,
      },
      {
        heading: "Age < 18",
        headContent: "",
        content: `
          <ul>
            <li>If the traveler is a minor and will not be accompanied by a parent or guardian, a consent letter from the parent or guardian is required.</li>
          </ul>
          `,
      },

      {
        heading: "Proof of Business",
        headContent: "",
        content: "",
      },
      {
        heading: "Retirement Letter(if Retired)",
        headContent: "",
        content: "",
      },
      {
        heading: " Student id card (If Student)",
        headContent: "",
        content: `
          <ul>
            <li>A copy of the student ID card is required to verify proof of education.</li>
            <li>A formal No Objection Certificate (NOC) from the school is required for the visit to Japan, indicating the approved leave period.</li>
          </ul>
          `,
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: `
          <ul>
            <li>Employer No Objection Certificate.</li>
            <li>Pay slips of the last three months.</li>
          </ul>
          `,
      },
      {
        heading: "Salary slip/Leave Certificate(If Employed)",
        headContent: "",
        content: "",
      },
    ],
    flight: 44000,
    PriceBreakage: {
      AFees: "2000",
      SCharge: "1500",
    },
    hotel: 6500,
    general: 5000,
  },
  {
    id: 44,
    name: "Jordan",
    pic: "/NewImage/jordan_11zon.jpg",
    price: "6200",
    date: dateHelper(2),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 2,
    Vtype: "E-Visa",
    sDuration: "30 Days",
    vValidity: "90 Days",
    pTime: "2 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 40000,
    PriceBreakage: {
      AFees: "5000",
      SCharge: "1200",
    },
    hotel: 4000,
    general: 3000,
  },
  {
    id: 45,
    name: "Kenya",
    pic: "/NewImage/kenya_11zon.jpg",
    price: "4800",
    date: dateHelper(6),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 6,
    Vtype: "E-Visa",
    sDuration: "30 Days",
    vValidity: "90 Days",
    pTime: "6 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Onward and Return Ticket",
        headContent: "",
        content: `
          <ul>
            <li>Provide proof of your flight reservation.</li>
          </ul>
          `,
      },
      {
        heading: "Yellow fever Vaccination",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: `
          <ul>
            <li>Submit a clear JPEG copy of your hotel booking confirmation.</li>
          </ul>
          `,
      },
    ],
    flight: 50000,
    PriceBreakage: {
      AFees: "4300",
      SCharge: "500",
    },
    hotel: 7000,
    general: 5000,
  },
  {
    id: 46,
    name: "Kyrgyzstan",
    pic: "/NewImage/Kyrgyzstan_11Zom.jpg",
    price: "17300",
    date: dateHelper(16),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 16,
    Vtype: "E-Visa",
    sDuration: "30 Days",
    vValidity: "30 Days",
    pTime: "16 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
    ],
    flight: 75000,
    PriceBreakage: {
      AFees: "14600",
      SCharge: "2700",
    },
    hotel: 5000,
    general: 4000,
  },
  {
    id: 47,
    name: "Laos",
    pic: "/NewImage/laos_11zon.jpg",
    price: "4500",
    date: dateHelper(8),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 8,
    Vtype: "E-Visa",
    sDuration: "30 Days",
    vValidity: "60 Days",
    pTime: "8 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 50000,
    PriceBreakage: {
      AFees: "4200",
      SCharge: "300",
    },
    hotel: 3000,
    general: 3000,
  },
  {
    id: 48,
    name: "Lebanon",
    pic: "/NewImage/lebanon_11zon.jpg",
    price: "10700",
    date: dateHelper(30),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 30,
    Vtype: "Sticker",
    sDuration: "30 Days",
    vValidity: "60 Days",
    pTime: "30 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "ITR (2 year)",
        headContent: "",
        content: "",
      },
      {
        heading: "Bank Statement(3 Month)",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Cover Letter",
        headContent: "",
        content: "",
      },
    ],
    flight: 80000,
    PriceBreakage: {
      AFees: "8400",
      SCharge: "2300",
    },
    hotel: 9000,
    general: 7500,
  },
  {
    id: 49,
    name: "Liechtenstein",
    pic: "/NewImage/liechtenstein_11zon.jpg",
    price: "3500",
    date: dateHelper(21),
    tag: "Get Visa appointment  on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 21,
    Vtype: "Sticker,Appointment",
    sDuration: "90 Days",
    vValidity: "Variable",
    pTime: "21 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 60000,
    PriceBreakage: {
      para: "Fees include appointment cost only",
    },
    hotel: 12000,
    general: 7500,
  },
  {
    id: 50,
    name: "Madagascar",
    pic: "/NewImage/madagascar_11zon.jpg",
    price: "2800",
    date: dateHelper(3),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 3,
    Vtype: "E-Visa",
    sDuration: "15 Days",
    vValidity: "15 Days",
    pTime: "3 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 55000,
    PriceBreakage: {
      AFees: "2300",
      SCharge: "500",
    },
    hotel: 6000,
    general: 5000,
  },
  {
    id: 51,
    name: "Malaysia",
    pic: "/NewImage/malaysia_11zon.png",
    price: "250",
    date: dateHelper(3),
    tag: "Get Arrival Card on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 3,
    Vtype: "E-Visa",
    sDuration: "30 Days",
    vValidity: "30 Days",
    pTime: "3 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
    ],
    flight: 30000,
    PriceBreakage: {
      para: "Fees include mandatory arrival card",
    },
    hotel: 2500,
    general: 3000,
  },
  // {
  //   id: Math.random(),
  //   name: "Maldives",
  //   pic: "./images/maldives.jpg",
  //   price: "10000",
  //   date:new Date()
  // },
  {
    id: 52,
    name: "Malta",
    pic: "/NewImage/malta_11zon.jpg",
    price: "3500",
    date: dateHelper(6),
    tag: "Get Visa Appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 6,
    Vtype: "Sticker,Appointment",
    sDuration: "90 Days",
    vValidity: "Variable",
    pTime: "6 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 70000,
    PriceBreakage: {
      para: "Fees include appointment cost only",
    },
    hotel: 8000,
    general: 7000,
  },
  // {
  //   id: Math.random(),
  //   name: "Marshall Island",
  //   pic: "./images/marshall-island.jpg",
  //   price: "10000",
  //   date:new Date()
  // },
  // {
  //   id: Math.random(),
  //   name: "Mauritius",
  //   pic: "./images/mauritius.jpg",
  //   price: "10000",
  //   date:new Date()
  // },
  // {
  //   id: Math.random(),
  //   name: "Moldova",
  //   pic: "./images/moldova.jpg",
  //   price: "10000",
  //   date:new Date()
  // },
  {
    id: 53,
    name: "Mongolia",
    pic: "/NewImage/mongolia_11zon.jpg",
    price: "1450",
    date: dateHelper(4),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 4,
    Vtype: "E-Visa",
    sDuration: "30 Days",
    vValidity: "120 Days",
    pTime: "4 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 90000,
    PriceBreakage: {
      AFees: "800",
      SCharge: "650",
    },
    hotel: 4000,
    general: 4000,
  },
  {
    id: 54,
    name: "Morocco",
    pic: "/NewImage/morocco_11zon.jpg",
    price: "7800",
    date: dateHelper(6),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 6,
    Vtype: "E-Visa",
    sDuration: "30 Days",
    vValidity: "180 days",
    pTime: "6 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Yellow fever Vaccination",
        headContent: "",
        content: "",
      },
      {
        heading: "ITR (2 year)",
        headContent: "",
        content: "",
      },
      {
        heading: "Bank Statement(3 Month)",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Itinerary",
        headContent: "",
        content: "",
      },
      {
        heading: "Visa Form",
        headContent: "",
        content: "",
      },
      {
        heading: "PanCard",
        headContent: "",
        content: "",
      },
      {
        heading: "Adhaar Card",
        headContent: "",
        content: "",
      },
      {
        heading: " Cover Letter",
        headContent: "",
        content: "",
      },
      {
        heading: "Proof of Business",
        headContent: "",
        content: "",
      },
      {
        heading: "Retirement Letter(if Retired)",
        headContent: "",
        content: "",
      },
      {
        heading: "Salary slip/Leave Certificate(If Employed)",
        headContent: "",
        content: "",
      },
      {
        heading: " Student id card (If Student)",
        headContent: "",
        content: "",
      },
    ],
    flight: 95000,
    PriceBreakage: {
      AFees: "6000",
      SCharge: "1800",
    },
    hotel: 8000,
    general: 6000,
  },
  {
    id: 55,
    name: "Mozambique",
    pic: "/NewImage/mozambique_11zon.jpg",
    price: "2950",
    date: dateHelper(10),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 10,
    Vtype: "E-Visa",
    sDuration: "30 Days",
    vValidity: "60 Days",
    pTime: "10 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Yellow fever Vaccination",
        headContent: "",
        content: "",
      },
    ],
    flight: 65000,
    PriceBreakage: {
      AFees: "14000",
      SCharge: "500",
    },
    hotel: 13000,
    general: 7500,
  },
  // {
  //   id: Math.random(),
  //   name: "Nepal",
  //   pic: "/images/nepal.jpg",
  //   price: "10000",
  //   date:new Date()
  // },
  {
    id: 56,
    name: "Netherlands",
    pic: "/NewImage/netherlands_11zon.jpg",
    price: "3500",
    date: dateHelper(30),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 30,
    Vtype: "Sticker,Appointment",
    sDuration: "90 Days",
    vValidity: "Variable",
    pTime: "30 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Cover Letter",
        headContent: "",
        content: `
          <ul>
            <li>Clearly state the purpose of your trip with a full itinerary.</li>
          </ul>
        `,
      },
      {
        heading: "ITR (3 Year)",
        headContent: "",
        content: `
          <ul>
            <li>Clearly state the purpose of your trip with a full itinerary.</li>
          </ul>
        `,
      },
      {
        heading: "Flight Ticket",
        headContent: "",
        content: `
          <ul>
            <li>Confirmation of round-trip flight ticket booking</li>
          </ul>
        `,
      },
      {
        heading: "Hotel Ticket",
        headContent: "",
        content: `
          <ul>
            <li>For hotel stays or holiday rentals, provide booking confirmations.</li>
            <li>If you plan to stay with a family member or friend, you must provide proof of sponsorship and accommodation from your host.</li>
            <li>If your travel includes multiple Schengen States, ensure you have proof of lodging arrangements for each of them.</li>
          </ul>
        `,
      },
      {
        heading: "If Employed",
        headContent: "",
        content: `
          <ul>
            <li>Employment contract</li>
            <li>Pay slips of the last 3 months.</li>
            <li>Employer's NOC letter confirming approved leave with company seal.</li>
          </ul>
        `,
      },
      {
        heading: "Age<18",
        headContent: "",
        content: `
          <ul>
            <li>For a minor traveling with only one parent (unless that parent has sole custody or guardianship): A notarized consent letter from the other parent or guardian is needed.</li>
            <li>Copies of the parents' passports should be included. If not applicable, submit the applicant's birth certificate and copies of the parents' ID cards.</li>
          </ul>
        `,
      },
      {
        heading: "If Married",
        headContent: "",
        content: `
          <ul>
            <li>If the applicant is married and doesn't have proof of marital status in their passport, they should provide a copy of the ration card if it's applicable.</li>
          </ul>
        `,
      },
      {
        heading: "Bank Statement (6 Months)",
        headContent: "",
        content: `
          <ul>
            <li>ISubmit your own recent bank statement, covering the past six months, with an official stamp and signature from the bank.</li>
            <li>The statement should be no more than one week old at the time of application.</li>
            <li>Recommended bank balance is at least Euro 55 (INR 4,800/-) per person per day for the entire trip.</li>
          </ul>
        `,
      },
    ],
    flight: 45000,
    PriceBreakage: {
      para: "Fees include appointment cost only",
    },
    hotel: 8000,
    general: 7500,
  },
  {
    id: 57,
    name: "New Zealand",
    pic: "/NewImage/new-zealand_11zon.jpg",
    price: "24500",
    date: dateHelper(25),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 25,
    Vtype: "E-Visa",
    sDuration: "6 Month ",
    vValidity: "18 months",
    pTime: "25 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Yellow fever Vaccination",
        headContent: "",
        content: "",
      },
      {
        heading: "ITR (2 year)",
        headContent: "",
        content: "",
      },
      {
        heading: "Physical Bank Statement(3 Month)",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Itinerary",
        headContent: "",
        content: "",
      },
      {
        heading: "Visa Form",
        headContent: "",
        content: "",
      },
      {
        heading: "PanCard",
        headContent: "",
        content: "",
      },
      {
        heading: "Adhaar Card",
        headContent: "",
        content: "",
      },
      {
        heading: " Cover Letter",
        headContent: "",
        content: "",
      },

      {
        heading: "Proof of Business",
        headContent: "",
        content: "",
      },
      {
        heading: "Retirement Letter(if Retired)",
        headContent: "",
        content: "",
      },
      {
        heading: "Salary slip/Leave Certificate(If Employed)",
        headContent: "",
        content: "",
      },
      {
        heading: " Student id card (If Student)",
        headContent: "",
        content: "",
      },
    ],
    flight: 55000,
    PriceBreakage: {
      AFees: "23000",
      SCharge: "1500",
    },
    hotel: 7500,
    general: 6000,
  },
  {
    id: 58,
    name: "Norway",
    pic: "/NewImage/norway_11zon.jpg",
    price: "3500",
    date: dateHelper(21),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 21,
    Vtype: "Sticker,Appointment",
    sDuration: "90 Days",
    vValidity: "Variable",
    pTime: "21 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "ITR (3 Year)",
        headContent: "",
        content: "",
      },
      {
        heading: "Cover Letter",
        headContent: "",
        content: `
          <ul>
            <li>Signed cover letter from the Application Portal Norway received by email</li>
          </ul>
        `,
      },
      {
        heading: "Visa Form",
        headContent: "",
        content: `
          <ul>
            <li>Completed and signed by the applicant</li>
          </ul>
        `,
      },
      {
        heading: "Flight Ticket",
        headContent: "",
        content: `
          <ul>
            <li>Include both onward and return tickets.</li>
          </ul>
        `,
      },
      {
        heading: "Bank Statement (6 Months)",
        headContent: "",
        content: `
          <ul>
            <li>Submit your own recent bank statement, covering the past six months, with an official stamp and signature from the bank.</li>
            <li>The statement should be no more than one week old at the time of application.</li>
            <li>Recommended bank balance is at least NOK 500 (INR 3,700/-) per day per person for the entire trip.</li>
          </ul>
        `,
      },
      {
        heading: "If Student",
        headContent: "",
        content: `
          <ul>
            <li>Original leave permission letter from the school/college/university.</li>
            <li>Proof of enrollment or registration in your school/college/university.</li>
          </ul>
        `,
      },
      {
        heading: "If Employed",
        headContent: "",
        content: `
          <ul>
            <li>Employment Contract</li>
            <li>No Objection Certificate (NOC) with leave dates from your employer on company letterhead.</li>
            <li>Pay slips for the last 3 months</li>
          </ul>
        `,
      },
      {
        heading: "Visa Form",
        headContent: "",
        content: `
          <ul>
            <li>Completed and signed by the applicant</li>
          </ul>
        `,
      },
    ],
    flight: 100000,
    PriceBreakage: {
      para: "Fees include appointment cost only",
    },
    hotel: 15000,
    general: 7500,
  },
  {
    id: 59,
    name: "Oman",
    pic: "/NewImage/oman_11zon.jpg",
    price: "6500",
    date: dateHelper(3),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 3,
    Vtype: "E-Visa",
    sDuration: "30 Days",
    vValidity: "90 Days",
    pTime: "3 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "PanCard",
        headContent: "",
        content: "",
      },
    ],
    flight: 35000,
    PriceBreakage: {
      AFees: "6000",
      SCharge: "500",
    },
    hotel: 4000,
    general: 4000,
  },
  {
    id: 60,
    name: "Papua New Guinea",
    pic: "/NewImage/papua-guinea_11zon.jpg",
    price: "6500",
    date: dateHelper(10),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 10,
    Vtype: "E-Visa",
    sDuration: "30 Days",
    vValidity: "180 Days",
    pTime: "10 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },

      {
        heading: "ITR (2 year)",
        headContent: "",
        content: "",
      },
      {
        heading: "Bank Statement(6 Month)",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Itinerary",
        headContent: "",
        content: "",
      },
      {
        heading: "Medical Report",
        headContent: "",
        content: "",
      },

      {
        heading: " Cover Letter",
        headContent: "",
        content: "",
      },
    ],
    flight: 250000,
    PriceBreakage: {
      AFees: "4200",
      SCharge: "2300",
    },
    hotel: 11000,
    general: 7500,
  },
  // {
  //   id: Math.random(),
  //   name: "Oleksandr P",
  //   pic: "/images/pexels-oleksandr-p-1002541.jpg",
  //   price: "10000",
  //   date:new Date()
  // },
  // {
  //   id: 95,
  //   name: "Philippines",
  //   pic: "/NewImage/philippines_11zon.jpg",
  //   price: "6500",
  //   date: dateHelper(29),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 29,
  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },
  //     {
  //       heading: "Air-Ticket",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Yellow fever Vaccination",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "ITR (2 year)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Bank Statement(3 Month)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Hotel Booking",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Itinerary",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Visa Form",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "PanCard",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Adhaar Card",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: " Cover Letter",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Salary slip/Leave Certificate(If Employed)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Proof of Business",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Retirement Letter(if Retired)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: " Student id card (If Student)",
  //       headContent: "",
  //       content: "",
  //     },
  //   ],
  //   flight: 25000,
  //   PriceBreakage: {
  //     AFees: "5000",
  //     SCharge: "1500",
  //   },
  //   hotel: 3500,
  //   general: 3000,
  // },
  {
    id: 61,
    name: "Poland",
    pic: "/NewImage/poland_11zon.jpg",
    price: "3500",
    date: dateHelper(8),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 8,
    Vtype: "Sticker,Appointment",
    sDuration: "90 Days",
    vValidity: "Variable",
    pTime: "8 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 45000,
    PriceBreakage: {
      para: "Fees include appointment cost only",
    },
    hotel: 6500,
    general: 5000,
  },
  // {
  //   id: 62,
  //   name: "Qatar",
  //   pic: "/NewImage/qatar_11zon.jpg",
  //   price: "1150",
  //   date: dateHelper(4),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 7,
  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },
  //     {
  //       heading: "Hotel Booking from discover Qatar",
  //       headContent: "",
  //       content: "",
  //     },
  //   ],
  //   flight: 25000,
  //   PriceBreakage: {
  //     AFees: "1200",
  //     SCharge: "750",
  //   },
  //   hotel: 11000,
  //   general: 7500,
  // },
  {
    id: 63,
    name: "Russia",
    pic: "/NewImage/russia_11zon.jpg",
    price: "4900",
    date: dateHelper(3),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 3,
    Vtype: "E-Visa",
    sDuration: "16 Days",
    vValidity: "60 Days",
    pTime: "3 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
    ],
    flight: 85000,
    PriceBreakage: {
      AFees: "4500",
      SCharge: "400",
    },
    hotel: 3000,
    general: 2500,
  },
  // {
  //   id: 64,
  //   name: "Rwanda",
  //   pic: "/NewImage/rwanda_11zon.jpg",
  //   price: "5300",
  //   date: dateHelper(8),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 8,
  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },
  //   ],
  //   flight: 60000,
  //   PriceBreakage: {
  //     AFees: "4500",
  //     SCharge: "800",
  //   },
  //   hotel: 6000,
  //   general: 5000,
  // },
  {
    id: 65,
    name: "Umrah",
    pic: "/NewImage/Umrah.jpg",
    price: "12900",
    date: dateHelper(7),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 7,
    Vtype: "E-Visa",
    sDuration: "30 Days",
    vValidity: "90 Days",
    pTime: "7 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 40000,
    PriceBreakage: {
      AFees: "11500",
      SCharge: "1400",
    },
    hotel: 1500,
    general: 3000,
  },
  // {
  //   id: 66,
  //   name: "India",
  //   pic: "/NewImage/India-temple.jpg",
  //   price: "10500",
  //   date: dateHelper(5),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 5,
  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },
  //   ],
  //   flight: 20000,
  //   PriceBreakage: {
  //     AFees: "9000",
  //     SCharge: "1500",
  //   },
  //   hotel: 3000,
  //   general: 2000,
  // },
  {
    id: 68,
    name: "Saudi Arabia",
    pic: "/NewImage/saudi-arabia_11zon.jpg",
    price: "11800",
    date: dateHelper(5),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 5,
    Vtype: "E-Visa",
    sDuration: "90 Days",
    vValidity: "365 Days",
    pTime: "5 Working Days",

    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Aadhaar Card",
        headContent: "",
        content: `
          <ul>
            <li>Copy of both the front and back of the Aadhar card.</li>
          </ul>
          `,
      },
      {
        heading: "Air Ticket",
        headContent: "",
        content: "",
      },

      {
        heading: "Bank Statement(3 Month)",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "ITR",
        headContent: "",
        content: "",
      },
      {
        heading: "Cover letter",
        headContent: "",
        content: "",
      },
      {
        heading: "Itinerary",
        headContent: "",
        content: "",
      },
      {
        heading: "Medical Report",
        headContent: "",
        content: "",
      },
      {
        heading: "Educational Certificate",
        headContent: "",
        content: `
          <ul>
            <li>For Mumbai applicants - Provide a coloured copy of the relevant educational qualifications related to the applicant's occupation. The original must be produced for verification.</li>
            <li>For Delhi applicants - Submit a coloured copy of the relevant educational qualifications duly attested by the Ministry of External Affairs in India.</li>
          </ul>
          `,
      },
    ],
    flight: 25000,
    PriceBreakage: {
      AFees: "10000",
      SCharge: "1800",
    },
    hotel: 5500,
    general: 4500,
  },
  // {
  //   id: Math.random(),
  //   name: "Senegal",
  //   pic: "./images/senegal.jpg",
  //   price: "10000",
  //   date:new Date()
  // },
  // {
  //   id: Math.random(),
  //   name: "Seychelles",
  //   pic: "/images/seychelles.jpg",
  //   price: "10000",
  //   date:new Date()
  // },
  {
    id: 69,
    name: "Singapore",
    pic: "/NewImage/singapore_11zon.jpg",
    price: "2800",
    date: dateHelper(6),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 6,
    Vtype: "E-Visa",
    sDuration: "30 Days",
    vValidity: "2 to 24 months",
    pTime: "6 Working Days",
    docs: [
      {
        heading: "2 Photos",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },

      {
        heading: "Cover Letter",
        headContent: "",
        content: `
          <ul>
            <li>Indicating the purpose of travel, number of days, passport and travel details. Cover letter should be on business letterhead if the applicant is a business owner</li>
          </ul>
          `,
      },
      {
        heading: "Visa Application Form",
        headContent: "",
        content: `
          <ul>
            <li>Do not fill in the date field.</li>
            <li>Parents should sign on behalf of minors.</li>
            <li>For applicants under the Chennai jurisdiction, please sign across the photograph on the form (refer to the attached sample file for clarification).</li>
          </ul>
          `,
      },
      {
        heading: "Bank Statement(6 Month)",
        headContent: "",
        content: `
          <ul>
            <li>The bank statement should be recent and dated within 3 days from the application date.</li>
          </ul>
          `,
      },
      {
        heading: "Flight Booking",
        headContent: "",
        content: `
          <ul>
            <li>Confirmation of round-trip booking</li>
          </ul>
          `,
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: `
          <ul>
            <li>Proof of hotel bookings covering the entire duration of your stay in Singapore. An invitation letter to be uploaded if you are staying with friends and family</li>
          </ul>
          `,
      },
      {
        heading: "Leave Certificate (If Salaried)",
        headContent: "",
        content: "",
      },
      {
        heading: "Business Proof (If Self-Employed)",
        headContent: "",
        content: "",
      },
      {
        heading: "Employment Details (If Salaried)",
        headContent: "",
        content: `
          <ul>
            <li>Pay slips of last 3 months</li>
            <li>A valid letter of employment, an offer letter or official company ID proof is necessary to show proof of employment</li>
          </ul>
          `,
      },
      {
        heading: "Student Id-Card (If Student)",
        headContent: "",
        content: `
          <ul>
            <li>Provide a copy of your school or college identification.</li>
          </ul>
          `,
      },
      {
        heading: "If Divorced",
        headContent: "",
        content: `
          <ul>
            <li>If divorced, please provide a copy of your divorce certificate as proof of separation.</li>
          </ul>
          `,
      },
    ],

    flight: 30000,
    PriceBreakage: {
      AFees: "2400",
      SCharge: "400",
    },
    hotel: 6500,
    general: 5500,
  },
  {
    id: 70,
    name: "Slovakia",
    pic: "/NewImage/slovakia_11zon.jpg",
    price: "3500",
    date: dateHelper(21),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 21,
    Vtype: "Sticker,Appointment",
    sDuration: "90 Days",
    vValidity: "Variable",
    pTime: "21 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 65000,
    PriceBreakage: {
      para: "Fees include appointment cost only",
    },
    hotel: 4000,
    general: 3000,
  },
  {
    id: 71,
    name: "Slovenia",
    pic: "/NewImage/slovenia_11zon.jpg",
    price: "3500",
    date: dateHelper(23),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 23,
    Vtype: "Sticker,Appointment",
    sDuration: "90 Days",
    vValidity: "Variable",
    pTime: "23 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 75000,
    PriceBreakage: {
      para: "Fees include appointment cost only",
    },
    hotel: 10000,
    general: 7500,
  },
  // {
  //   id: Math.random(),
  //   name: "Somalia",
  //   pic: "./images/somalia.jpg",
  //   price: "10000",
  //   date:new Date()
  // },
  // {
  //   id: 72,
  //   name: "South Africa",
  //   pic: "/NewImage/south-africa_11zon.jpg",
  //   price: "7100",
  //   date: dateHelper(31),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 31,
  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },
  //     {
  //       heading: "Air-Ticket",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Yellow fever Vaccination",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Visa Form",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Bank Statement(3 Month)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Hotel Booking",
  //       headContent: "",
  //       content: "",
  //     },

  //     {
  //       heading: " Cover Letter",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Salary slip/Leave Certificate(If Employed)",
  //       headContent: "",
  //       content: "",
  //     },
  //   ],
  //   flight: 70000,
  //   PriceBreakage: {
  //     AFees: "4800",
  //     SCharge: "2300",
  //   },
  //   hotel: 4500,
  //   general: 3500,
  // },
  {
    id: 73,
    name: "South Korea",
    pic: "/NewImage/south-korea_11zon.jpg",
    price: "7100",
    date: dateHelper(10),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 10,
    Vtype: "E-Visa",
    sDuration: "30 Days",
    vValidity: "90 Days",
    pTime: "10 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: `
          <ul>
            <li>Onward and return flight tickets.</li>
          </ul>
          `,
      },
      {
        heading: "Yellow fever Vaccination",
        headContent: "",
        content: "",
      },
      {
        heading: "ITR (2 year)",
        headContent: "",
        content: "",
      },
      {
        heading: "Bank Statement(3 Month)",
        headContent: "",
        content: `
          <ul>
            <li>Original personal bank statement for the last six months with a minimum balance of Rs. 150,000 per person.</li>
          </ul>
          `,
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: `
          <ul>
            <li>Confirmed refundable hotel booking covering the entire stay.</li>
          </ul>
          `,
      },
      {
        heading: "Itinerary",
        headContent: "",
        content: "",
      },
      {
        heading: "Visa Form",
        headContent: "",
        content: `
          <ul>
            <li>Complete the online Visa application form</li>
          </ul>
          `,
      },
      {
        heading: "PanCard",
        headContent: "",
        content: "",
      },
      {
        heading: "Adhaar Card",
        headContent: "",
        content: "",
      },
      {
        heading: " Cover Letter",
        headContent: "",
        content: `
          <ul>
            <li>Addressed to the Embassy/Consulate General of South Korea.</li>
            <li>Include passport details, work profile, travel purpose, stay details, travel dates, expense details, home address, and mobile number.</li>
          </ul>
          `,
      },
      {
        heading: "Salary slip/Leave Certificate(If Employed)",
        headContent: "",
        content: `
          <ul>
            <li>Last 12 months' salary slips with company seal and signature.</li>
            <li>Provide a leave letter from the company with necessary details.</li>
          </ul>
          `,
      },
      {
        heading: "Proof of Business",
        headContent: "",
        content: "",
      },
      {
        heading: "Retirement Letter(if Retired)",
        headContent: "",
        content: "",
      },
      {
        heading: " Student id card (If Student)",
        headContent: "",
        content: `
          <ul>
            <li>Leave sanction letter from school/college on letterhead.</li>
            <li>School/college ID copy.</li>
          </ul>
          `,
      },
      {
        heading: "Indian company Letter",
        headContent: "",
        content: `
          <ul>
            <li>Draft a formal letter on your company letterhead addressed to the Embassy or Consulate General of South Korea.</li>
            <li>Include details such as Applicant Name, Passport Number, Travel Date, Purpose of Travel, Designation, and indicate who will bear the expenses.</li>
            <li>Provide the name, designation, and mobile number of the person signing the letter, along with the company seal.</li>
          </ul>
          `,
      },
      {
        heading: " Invitation Letter",
        headContent: "",
        content: `
          <ul>
            <li>Obtain an invitation letter from the Korean company hosting your visit.</li>
            <li>Include essential details like Applicant Name, Passport Number, Travel Date, Purpose of Travel, and Designation.</li>
            <li>Specify the party responsible for expenses and provide the name, designation, and company seal of the person issuing the invitation.</li>
            <li>Include contact details of the person in charge at the Korean company.</li>
            <li>Inviter's Details (for Individuals or Companies): For companies, provide the name and phone number of the person in charge. and For individuals, include the full name, ID number (Foreigner Registration Number/Alien Registration Number), and phone number of the inviter.</li>

          </ul>
          `,
      },
    ],
    flight: 30000,
    PriceBreakage: {
      AFees: "3500",
      SCharge: "3600",
    },
    hotel: 3500,
    general: 3000,
  },
  {
    id: 74,
    name: "Spain",
    pic: "/NewImage/spain_11zon.jpg",
    price: "3500",
    date: dateHelper(16),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 16,
    Vtype: "Sticker,Appointment",
    sDuration: "90 Days",
    vValidity: "Variable",
    pTime: "16 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Flight Booking",
        headContent: "",
        content: `
          <ul>
            <li>Confirmation of round-trip booking is necessary.</li>
          </ul>
        `,
      },
      {
        heading: "ITR (3 year)",
        headContent: "",
        content: `
          <ul>
            <li>Confirmation of round-trip booking is necessary.</li>
          </ul>
        `,
      },
      {
        heading: "Pan Card Copy",
        headContent: "",
        content: `
          <ul>
            <li>Copy of PAN card is mandatory for all the applicants.</li>
          </ul>
        `,
      },
      {
        heading: "Visa Form",
        headContent: "",
        content: `
          <ul>
            <li>Copy of PAN card is mandatory for all the applicants.</li>
          </ul>
        `,
      },
      {
        heading: "Age<18",
        headContent: "",
        content: `
          <ul>
            <li>Scanned copy of passport of both the parents is required.</li>
          </ul>
        `,
      },
      {
        heading: "If Married",
        headContent: "",
        content: `
          <ul>
            <li>Applicable if married.</li>
          </ul>
        `,
      },
      {
        heading: "Cover letter",
        headContent: "",
        content: `
          <ul>
            <li>Clearly state the purpose of your travel.</li>
            <li>Specify the number of days you intend to stay.</li>
            <li>Provide essential passport and travel details.</li>
          </ul>
        `,
      },
      {
        heading: "If Employed",
        headContent: "",
        content: `
          <ul>
            <li>Pay slips of last 3 months</li>
            <li>Employer's letter confirming approved leave.</li>
            <li>Provide the original company letter on official letterhead, including the authorized signatory's name, designation, contact information, and company seal stamp.</li>
          </ul>
        `,
      },
      {
        heading: "Bank Statement 6 Months",
        headContent: "",
        content: `
          <ul>
            <li>Submit your own recent bank statement, covering the past six months, with an official stamp and signature from the bank.</li>
            <li>The bank statement should be no more than one week old at the time of application.</li>
            <li>It is recommended to transfer funds to maintain this balance at least 15 days prior to the appointment</li>
            <li>The recommended bank balance is at least 100 Euros (INR 8,500/-) per day per traveler.</li>
          </ul>
        `,
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: `
          <ul>
            <li> <strong>For individuals staying at a hotel:</strong>  Display proof of hotel bookings covering the entire duration of your stay in Spain.</li>
            <li> <strong>For individuals staying with friends/family:</strong>     An invitation letter is necessary.</li>
          </ul>
        `,
      },
    ],
    flight: 45000,
    PriceBreakage: {
      para: "Fees include the appointment cost only",
    },
    hotel: 10000,
    general: 7500,
  },
  {
    id: 75,
    name: "Sri Lanka",
    pic: "/NewImage/srilanka_11zon.jpeg",
    price: "2900",
    date: dateHelper(2),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 2,
    Vtype: "E-Visa",
    sDuration: "30 Days",
    vValidity: "30 Days",
    pTime: "2 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 10000,
    PriceBreakage: {
      AFees: "1600",
      SCharge: "1300",
    },
    hotel: 3500,
    general: 3000,
  },
  // {
  //   id: Math.random(),
  //   name: "Saint Kitts and Nevis",
  //   pic: "./images/st-kitts-nevis.jpg",
  //   price: "10000",
  //   date:new Date()
  // },
  // {
  //   id: Math.random(),
  //   name: "Saint Lucia",
  //   pic: "./images/st-lucia.jpg",
  //   price: "10000",
  //   date:new Date()
  // },
  {
    id: 76,
    name: "Sweden",
    pic: "/NewImage/sweden_11zon.jpg",
    price: "3500",
    date: dateHelper(15),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 15,
    Vtype: "Sticker,Appointment",
    sDuration: "90 Days",
    vValidity: "Variable",
    pTime: "15 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Visa Form",
        headContent: "",
        content: `
          <ul>
            <li>Completed and signed by the applicant
             In case of a minor, signed twice by legal guardian or authorization of third person (with child's custody proof)</li>
          </ul>
        `,
      },
      {
        heading: "Flight Ticket",
        headContent: "",
        content: `
          <ul>
            <li>Proof of a return ticket.</li>
            <li>If travelling to multiple Schengen states, provide evidence of intra-Schengen flight reservations, train itineraries, or car rentals.</li>
          </ul>
        `,
      },
      {
        heading: "Travel Insurance",
        headContent: "",
        content: `
          <ul>
            <li>Minimum coverage of 30,000 Euros, explicitly specifying coverage for accidents, illness, medical emergencies, and evacuation.</li>
          </ul>
        `,
      },
      {
        heading: "Bank Statement (6 Mnths)",
        headContent: "",
        content: `
          <ul>
            <li>Submit your own recent bank statement, covering the past three months, with an official stamp and signature from the bank.</li>
            <li>The statement should be no more than one week old at the time of application.</li>
            <li> Recommended bank balance is at least SEK 450 (INR 3,300/-) per person per day for the entire trip.</li>
          </ul>
        `,
      },
      {
        heading: "Pan Card",
        headContent: "",
        content:"",
      },
      {
        heading: "Cover Letter",
        headContent: "",
        content: `
          <ul>
            <li>Mention your name, travel details, expenses, and purpose of the visit.</li>
          </ul>
        `,
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: `
          <ul>
            <li>Hotel reservations or rental documents of holiday homes or campus residence reservations for your entire Schengen stay.</li>
          </ul>
        `,
      },
      {
        heading: "ITR (2 Year)",
        headContent: "",
        content: `
          <ul>
            <li>Income tax return for the last two financial years</li>
          </ul>
        `,
      },
      {
        heading: "Original and old Passport",
        headContent: "",
        content: `
          <ul>
            <li>Original and copy of passport valid for at least 6 months from the date of travel</li>
            <li> Old passports, if any. If old passport is lost, please mention a note in the cover letter</li>
          </ul>
        `,
      },
      {
        heading: "If Married",
        headContent: "",
        content: `
          <ul>
            <li>Copies of the original Marriage Certificate.</li>
          </ul>
        `,
      },
      {
        heading: "If student",
        headContent: "",
        content: `
          <ul>
            <li>A letter confirming enrollment in the college or university</li>
          </ul>
        `,
      },
      {
        heading: "If Employed",
        headContent: "",
        content: `
          <ul>
            <li>Pay slips from the last three months</li>
            <li>A statement from the employer confirming approval of holidays</li>
          </ul>
        `,
      },
    ],
    flight: 45000,
    PriceBreakage: {
      para: "Fees include the appointment cost only",
      SCharge: "2000",
    },
    hotel: 8500,
    general: 7500,
  },
  {
    id: 77,
    name: "Switzerland",
    pic: "/NewImage/switzerland_11zon.jpg",
    price: "3500",
    date: dateHelper(27),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 27,
    Vtype: "Sticker,Appointment",
    sDuration: "90 Days",
    vValidity: "Variable",
    pTime: "27 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Cover Letter",
        headContent:"",
        content: `
          <ul>
            <li>Original Cover Letter should be submitted with the travel plan.</li>
            <li>If the applicant is submitting the applications of his/her family, then all applications must include a copy of the covering letter.</li>
            <li>All of the documents should be self-attested.</li>
          </ul>
        `,
      },
      {
        heading: "Copies of valid UK, USA, or Canada Visas",
        headContent:"",
        content: `
          <ul>
            <li>If you hold any valid visas from UK, USA or Canada, include them.</li>
          </ul>
        `,
      },
      {
        heading: "Visa of The Next Country",
        headContent:"",
        content: `
          <ul>
            <li>Next country visa/travel permit</li>
            <li>Not applicable if traveling back to country of own nationality</li>
          </ul>
        `,
      },
      {
        heading: "Refusal Notice",
        headContent:"",
        content: `
          <ul>
            <li>If you have been refused a visa by an Embassy or High Commission in the last 2 years, provide the refusal notice and a written explanation about the reasons for refusal.</li>
          </ul>
        `,
      },
      {
        heading: "ITR (3 Year)",
        headContent:"",
        content: ""
      },
      {
        heading: "Proof of Accommodation",
        headContent:"",
        content: `
          <ul>
            <li>Confirmation of hotel reservations, booking a package tour, and making advance payments.</li>
          </ul>
        `,
      },
      {
        heading: "6 Months Personal Bank Satement",
        headContent:"",
        content: `
          <ul>
            <li>Last 6 months Personal bank account statements with Bank Stamp and Signature</li>
          </ul>
        `,
      },
      {
        heading: "Flight Reservation",
        headContent:"",
        content: `
          <ul>
            <li>Confirmation of round-trip booking is necessary.</li>
            <li>Should include names of all the travelers.</li>
            <li>If applicable proof of intra-Schengen flight, train itinerary or car rental.</li>
          </ul>
        `,
      },
      {
        heading: "Previous Passpost",
        headContent:"",
        content: `
          <ul>
            <li>If any previous passports are present, they should be bound together with a rubber band, not stapled.</li>
          </ul>
        `,
      },
      {
        heading: "Visa Form",
        headContent:"",
        content: `
          <ul>
            <li>Visa application form signed by the applicant should be carried on the day of the appointment.</li>
            <li>If the applicant is a minor, the visa application should be signed twice by the legal guardian. If applicable, include authorization of a third person.</li>
            <li>It should prove the custody of the minor child, i.e., divorce papers, death certificate etc.</li>
          </ul>
        `,
      },
      {
        heading: "Travel Insurance",
        headContent:"",
        content: `
          <ul>
            <li>Travel insurance with the minimum coverage of 30,000 Euros required for Switzerland</li>
          </ul>
        `,
      },
      {
        heading: "Retired",
        headContent:"",
        content: `
          <ul>
            <li>Personal pension bank statement for the last 6 months.</li>
            <li>Proof of regular income generated by ownership of property or business.</li>
          </ul>
        `,
      },
      {
        heading: "If Student",
        headContent:"",
        content: `
          <ul>
            <li>A sponsorship letter that includes comprehensive information regarding the financial support being provided.</li>
            <li>Personal bank Statements for the past six months of the parents, legal guardian, or sponsor.</li>
            <li>Copies of the passports belonging to the parents or legal guardian.</li>
            <li>If student and above 16 years, an introduction letter from their School/College/University.</li>
            <li>Only ITR-V, ITR Verification Form or</li>
            <li>Acknowledgement of the last 2 assessment years (In originals and A4 size format)</li>
          </ul>
        `,
      },
      {
        heading: "If Employed",
        headContent:"",
        content: `
          <ul>
            <li>Last 3 months salary slips</li>
            <li>Originals and in A4 size format</li>
            <li>Last 6 months Salary bank account statements with Bank Stamp and Signature</li>
            <li>An original letter issued on the company's official letterhead, signed and stamped by the HR department or directorate.</li>
            <li>This letter should indicate the applicant's position, duration of employment, as well as the trip's purpose and travel dates.</li>
          </ul>
        `,
      },
      {
        heading: "Unemployed",
        headContent:"",
        content: `
          <ul>
            <li>A sponsorship letter that includes comprehensive information regarding the financial support being provided.</li>
            <li>Personal bank Statements for the past six months of the parents, legal guardian, or sponsor.</li>
            <li>Copies of the passports belonging to the parents or legal guardian.</li>
            <li>Only ITR-V, ITR Verification Form or</li>
            <li>Acknowledgement of the last 2 assessment years (In originals and A4 size format)</li>
          </ul>
        `,
      },
      {
        heading: "Age<18",
        headContent:"",
        content: `
          <ul>
            <li>If minors are not traveling with both parents, the consent must be signed by the other/non-traveling parent or legal guardian and certified by a public notary.</li>
            <li>If minors are traveling alone, the consent must be signed by both parents or legal guardian and certified by a public notary.</li>
          </ul>
        `,
      },
    ],
    flight: 100000,
    PriceBreakage: {
      para: "Fees include the appointment cost only",
    },
    hotel: 12000,
    general: 7500,
  },
  {
    id: 78,
    name: "Taiwan",
    pic: "/NewImage/taiwan_11zon.jpg",
    price: "1450",
    date: dateHelper(5),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 5,
    Vtype: "E-Visa",
    sDuration: "14 Days",
    vValidity: "90 Days",
    pTime: "5 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },

      {
        heading: "Flight Bookng",
        headContent: "",
        content: "",
      },
    ],
    flight: 35000,
    PriceBreakage: {
      AFees: "700",
      SCharge: "750",
    },
    hotel: 5000,
    general: 4000,
  },
  {
    id: 79,
    name: "Tajikistan",
    pic: "/NewImage/tajikistan_11zon.jpg",
    price: "6800",
    date: dateHelper(14),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 14,
    Vtype: "E-Visa",
    sDuration: "60 Days",
    vValidity: "60 Days",
    pTime: "14 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },

      {
        heading: "Flight Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
    ],
    flight: 55000,
    PriceBreakage: {
      AFees: "4500",
      SCharge: "2300",
    },
    hotel: 3500,
    general: 3000,
  },
  {
    id: 80,
    name: "Tanzania",
    pic: "/NewImage/tanzania_11zon.jpg",
    price: "5600",
    date: dateHelper(14),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 14,
    Vtype: "E-Visa",
    sDuration: "90 Days",
    vValidity: "90 Days",
    pTime: "14 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 40000,
    PriceBreakage: {
      AFees: "4500",
      SCharge: "1100",
    },
    hotel: 10000,
    general: 7500,
  },
  // {
  //   id: Math.random(),
  //   name: "Thailand",
  //   pic: "./images/thailand.jpg",
  //   price: "10000",
  //   date:new Date()
  // },
  // {
  //   id: 81,
  //   name: "Turkiye",
  //   pic: "/NewImage/turkiye_11zon.jpg",
  //   price: "9300",
  //   date: dateHelper(20),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 20,
  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },

  //     {
  //       heading: "Employement Documents",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Bank Statement(3 Month)",
  //       headContent: "",
  //       content: "",
  //     },
  //   ],
  //   flight: 35000,
  //   PriceBreakage: {
  //     AFees: "4300",
  //     SCharge: "5000",
  //   },
  //   hotel: 4000,
  //   general: 3500,
  // },
  // {
  //   id: 82,
  //   name: "UAE",
  //   pic: "/NewImage/UAE_11zon.jpg",
  //   price: "8400",
  //   date: dateHelper(3),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 3,
  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },

  //     {
  //       heading: "Pan Card",
  //       headContent: "",
  //       content: "",
  //     },
  //   ],
  //   flight: 30000,
  //   PriceBreakage: {
  //     AFees: "6000",
  //     SCharge: "2400",
  //   },
  //   hotel: 4000,
  //   general: 4000,
  // },
  {
    id: 83,
    name: "United Kingdom",
    pic: "/NewImage/uk_11zon.jpg",
    price: "15000",
    date: dateHelper(30),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 30,
    Vtype: "Sticker",
    sDuration: "180 Days",
    vValidity: "180 Days",
    pTime: "30 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },

      {
        heading: "ITR (2 year)",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Cover Letter",
        headContent: "",
        content: "",
      },
      {
        heading: "Bank Statement(6 Month)",
        headContent: "",
        content: `
          <ul>
            <li>Original bank statements covering the past six months, signed and stamped, from both the applicant and, if applicable, the sponsor.</li>
            <li>Should have balance of at least 150 euros per day of the trip per traveler </li>
            <li>Any abrupt deposits into your savings account must be appropriately explained and justified.</li>
          </ul>
          `,
      },
      {
        heading: "Business Proof (If Self-Employed)",
        headContent: "",
        content: "",
      },
      {
        heading: "Employment Documents (If Salaried)",
        headContent: "",
        content: `
          <ul>
            <li>Please submit a copy of the birth certificate.</li>
          </ul>
          `,
      },
      {
        heading: "Student Id-Card (If Student)",
        headContent: "",
        content: `
          <ul>
            <li>Provide a copy of your school or college identification.</li>
          </ul>
          `,
      },
      {
        heading: "Salary slip/Leave Certificate(If Employed)",
        headContent: "",
        content: `
          <ul>
            <li>Salary slips of last 3 months.</li>
            <li>Employer's letter confirming approved leave.</li>
            <li>Provide the original company letter on official letterhead, including the authorized signatory's name, designation, contact information, and company seal stamp.</li>
          </ul>
          `,
      },
      {
        heading: "If Married",
        headContent: "",
        content: `
          <ul>
            <li>Proof of marriage- marriage certificate</li>
          </ul>
          `,
      },
      {
        heading: "Age<18",
        headContent: "",
        content: `
          <ul>
            <li>If minor, provide your birth certificate</li>
            <li>If you are under 18 years of age and traveling with your parent(s) or legal guardian(s), this applies if they are not applying for a visa at the same time.</li>
          </ul>
          `,
      },
    ],
    flight: 80000,
    PriceBreakage: {
      para: "Fees include all charges at every step of Visa application",
    },
    hotel: 8000,
    general: 7500,
  },
  // {
  //   id: 84,
  //   name: "Uruguay",
  //   pic: "/NewImage/uruguay_11zon.jpg",
  //   price: "5200",
  //   date: dateHelper(24),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 24,
  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },
  //     {
  //       heading: "Air-Ticket",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Yellow fever Vaccination",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "ITR (2 year)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Bank Statement(3 Month)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Hotel Booking",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Itinerary",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Visa Form",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "PanCard",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Adhaar Card",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: " Cover Letter",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Salary slip/Leave Certificate(If Employed)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Proof of Business",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Retirement Letter(if Retired)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: " Student id card (If Student)",
  //       headContent: "",
  //       content: "",
  //     },
  //   ],
  //   flight: 160000,
  //   PriceBreakage: {
  //     para: "Fees include all charges at every step of Visa application",
  //   },
  //   hotel: 8500,
  //   general: 7500,
  // },
  {
    id: 85,
    name: "United States",
    pic: "/NewImage/us_11zon.jpg",
    price: "20000",
    aPrice: "15000",
    date: dateHelper(40),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 120,
    Vtype: "Sticker",
    sDuration: "6 Month",
    vValidity: "10 Year",
    pTime: "120 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: " Cover Letter",
        headContent: "",
        content: `
          <ul>
            <li>Clearly stating the purpose of travel, duration, and passport details.</li>
          </ul>
          `,
      },
      {
        heading: "Salary slip/Leave Certificate(If Employed)",
        headContent: "",
        content: "",
      },
      {
        heading: "Proof of Business",
        headContent: "",
        content: "",
      },
      {
        heading: "Bank Statement(6 Months)",
        headContent: "",
        content: "",
      },
      {
        heading: "Retirement Letter(if Retired)",
        headContent: "",
        content: "",
      },
      {
        heading: " Student id card (If Student)",
        headContent: "",
        content: "",
      },
      {
        heading: "DS 160 confirmation",
        headContent: "",
        content: "",
      },
      {
        heading: "ITR",
        headContent: "",
        content: `
          <ul>
            <li>Personal Income Tax Return for the last 3 years.</li>
          </ul>
          `,
      },
    ],
    flight: 100000,
    PriceBreakage: {
      para: "Fees include all charges at every step of Visa application",
    },
    hotel: 15000,
    general: 7500,
  },
  {
    id: 86,
    name: "Uzbekistan",
    pic: "/NewImage/uzbekistan_11zon.jpg",
    price: "2700",
    date: dateHelper(7),
    tag: "Get Visa response on time or free ",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 7,
    Vtype: "E-Visa",
    sDuration: "30 Days",
    vValidity: "90 Days",
    pTime: "7 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Yellow fever Vaccination",
        headContent: "",
        content: "",
      },
    ],
    flight: 25000,
    PriceBreakage: {
      AFees: "2000",
      SCharge: "700",
    },
    hotel: 3500,
    general: 3000,
  },
  {
    id: 87,
    name: "Vietnam",
    pic: "/NewImage/vietnam_11zon.jpg",
    price: "2750",
    date: dateHelper(4),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 4,
    Vtype: "E-Visa",
    sDuration: "30 Days",
    vValidity: "30 Days",
    pTime: "4 Working Days",

    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
    ],
    flight: 25000,
    PriceBreakage: {
      AFees: "2400",
      SCharge: "350",
    },
    hotel: 3500,
    general: 3000,
  },
  {
    id: 88,
    name: "Zambia",
    pic: "/NewImage/Zambia.jpg",
    price: "5800",
    date: dateHelper(12),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 12,
    Vtype: "E-Visa",
    sDuration: "30 Days",
    vValidity: "60 Days",
    pTime: "12 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Return Air Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Cover Letter",
        headContent: "",
        content: "",
      },
    ],
    PriceBreakage: {
      AFees: "4300",
      SCharge: "1500",
    },
  },
  {
    id: 89,
    name: "Uganda",
    pic: "/NewImage/Uganda.jpg",
    price: "8800",
    date: dateHelper(8),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 8,
    Vtype: "E-Visa",
    sDuration: "30 Days",
    vValidity: "90 Days",
    pTime: "8 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Return Air Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Cover Letter",
        headContent: "",
        content: "",
      },
      {
        heading: "Yellow Fever Certificate",
        headContent: "",
        content: "",
      },
    ],
    PriceBreakage: {
      AFees: "8400",
      SCharge: "400",
    },
  },
  {
    id: 90,
    name: "Togo",
    pic: "/NewImage/Togo.webp",
    price: "3500",
    date: dateHelper(11),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 11,
    Vtype: "E-Visa",
    sDuration: "15 Days",
    vValidity: "15 Days",
    pTime: "11 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    PriceBreakage: {
      AFees: "2100",
      SCharge: "1400",
    },
  },
  {
    id: 91,
    name: "South Sudan",
    pic: "/NewImage/sudan.jpg",
    price: "11300",
    date: dateHelper(7),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 7,
    Vtype: "E-Visa",
    sDuration: "30 Days",
    vValidity: "60 Days",
    pTime: "7 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Yellow Fever Certificate",
        headContent: "",
        content: "",
      },
    ],
    PriceBreakage: {
      AFees: "9800",
      SCharge: "1500",
    },
  },
  // {
  //   id: 92,
  //   name: "Romania",
  //   pic: "/NewImage/Romania.avif",
  //   price: "4500",
  //   date: dateHelper(30),
  //   tag: "Get Visa appointment on time or 100% refund",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 30,
  //   Vtype:"E-Visa",
  //   sDuration:"90 Days",
  //   vValidity:"90 Days",
  //   pTime:"30 Working Days",
  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },
  //   ],
  //   PriceBreakage: {
  //     para: "Fees include appointment cost only",
  //   },
  // },
  {
    id: 93,
    name: "Kuwait",
    pic: "/NewImage/Kuwait-City.webp",
    price: "7500",
    date: dateHelper(30),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 30,
    Vtype: "E-Visa",
    sDuration: "30 Days",
    vValidity: "60 Days",
    pTime: "30 Working Days",
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Visa Form",
        headContent: "",
        content: "",
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Bank Statement(3 Month)",
        headContent: "",
        content: "",
      },
    ],
    // flight: 25000,
    // hotel: 3500,
    // general: 3000,
  },
];

export const airPortCityList = [
  "Hyderabad",
  "Guwahati",
  "Amritsar",
  "Bhubaneswar",
  "Gaya",
  "New Delhi",
  "Port Blair",
  "Ahmedabad",
  "Bengaluru",
  "Mangalore",
  "Kozhikode",
  "Kochi",
  "Thiruvananthapuram",,
  "Nagpur",
  "Mumbai",
  "Jaipur",
  "Tiruchirappalli",
  "Chennai",
  "Kannur",
  "Lucknow",
  "Varanasi",
  "Kolkata",
  "Surat",
  "Indore",
  "Dabolim",
  "Srinagar",
  "Imphal",
  "Coimbatore",
  "Madurai",
  "Siliguri",
  "Mangalore",
  "Chandigarh",
  "Nashik",
  "Badodara",
  "Kushinagar"
]
