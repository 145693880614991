import React from "react";
import Header from "../../../../../Home/Header";
import CardMedia from "@mui/material/CardMedia";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { Link } from "react-router-dom";
import "./workPermitCards.css";
import { WorkPermitCountries } from "../WorkPermitDatabase";
import { Helmet } from "react-helmet";

const WorkCountryCards = () => {
  const currentUrl = window.location.href;
  return (
    <>
      <div className="ViaApply_Header dashboard_header">
        <Header />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Countries</title>
          <meta property="og:url" content={currentUrl} />
          <meta property="og:type" content="website" />
        </Helmet>
      </div>

      <div className="Country_UnivercityCards mainwraper">
        <div className="container">
          <div className="Univercity_CardsPage_heading">
            <h2>Countries</h2>
          </div>
          <div className="row">
            {WorkPermitCountries.map((Cards, index) => (
              <div className="col-lg-4 col-md-6  " key={Cards.id}>
                <Link to={`/startApplication/${Cards.id}`} id="myButton">
                  <div className="card">
                    <div className="card-img">                    
                      <CardMedia
                        image={Cards.pic}
                        component="img"
                        loading="lazy"
                        alt={` UAE Work-Permit Image `}
                      />
                    </div>
                    <div className="card_content">
                      <div className="country">{Cards.name} </div>
                      <div className="price">
                        <p>Price</p>
                        <MdOutlineCurrencyRupee /> {Cards.price}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkCountryCards;
