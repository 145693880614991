export const WorkPermitCountries = [
  {
    id: 1,
    name: "Greece",
    pic: "/NewImage/Greece-WorkPermit.avif",
    price: "550000",
    FirstFees: "1",
    days: 14,
    jobCards: [
      {
        id: 1,
        Country: "Greece",
        Job: "AirPort Job (Baggage Handler)",
        Salary: "950 EURO + OT",
        FirstFees: "1",
        Accomodation: "Provided by Company",
        skill: "English skill more than average",
        VisaFee: "Included",
        FlightT: "Included",
        PTime: "4 TO 6 Months",
        SC: "550000",
        Interview: "Required",
        Contract: "5 Year",
      },
    ],
  },
  {
    id: 2,
    name: "Cyprus",
    pic: "/NewImage/Nicosia-Cyprus-aerial-view.png",
    price: "450000",
    FirstFees: "1",
    days: 14,
    jobCards: [
      {
        id: 2,
        Country: "Cyprus",
        Job: "Welder",
        Salary: "1300 EURO ",
        FirstFees: "1",
        Accomodation: "Provided by Company",
        VisaFee: "Not Included",
        FlightT: "Not Included",
        PTime: "3 TO 4 Months",
        SC: "450000",
        Interview: "Required",
        Contract: "2 Year + Renewable",
      },
    ],
  },
 
];
